#section8{    
    background-color: var(--color-fondoSeccion2);
    height: 500px;
  }

  #divMenuSection8{
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    flex-direction: column;
    width: 100%;
    height: 100%;
}
#divContenedorSection8{
    width: 100%;
    height: 100%;
}
#contenedorSection8{
    display: flex;
    justify-content: center;
    align-items: center;
    width:100%;
    height: auto;
    background-color: rgba(240, 255, 255, 0.459);
    margin-top: 5rem;
}
#divTextSection8{
    display: flex; /* Convertirlo en un contenedor flexible */
    flex-direction: column; /* Para alinear el texto de arriba hacia abajo */
    align-items: center;
    padding: 10px;
    text-align: center; 
    width: 40rem;
    height: 15rem;
    justify-content: center;
}
#tituloProximamente {
    color: var(--color-tituloLetra);
    font-size:50px;
    font-weight: 650;
    text-decoration: none;
    align-items: center;
    margin-top: 5rem;
}

@media (max-width: 1550px){
    #tituloProximamente{
        font-size: 40px;
    }
}