
#section4{
    background-color: var(--color-fondoSeccion);
  }
  

#divMenuSection4{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 25rem;
}
#divContenedorSection4{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    position: absolute;
    top:2rem;
}

.faq__question-button1 {
    width: 100%;
    display: flex;
    justify-content: space-between;
    border: none;
    background-color: transparent;
    padding: 0px;
    cursor: pointer;
    padding-top: 18px;
    padding-bottom: 18px;
}

.faq__question-button1 text :hover{
    color: aqua;
}

@media (max-width: 1100px){
    .parrafosAcordeon{
        font-size: 12px;
    }
}

.faq__question {
    margin-top: 20px;
}

#contenedorSection4{
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    flex-direction: row;
    width:50%;
    height: auto;
    border-radius: 20px;
}

#descriptionSection4{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 0 120px 20px 150px ;
    width: 20rem;
    position: absolute;
    top: 3rem;    
    color: var(--color-comentarioCard);
}
#descriptionSection4 h1{
  color: var(--color-tituloLetra);
  font-size: 26px;
  font-weight: 650;
  text-decoration: none;
}

.accordion{
    width: 100%;
}

.faq{
    margin-top: 30px;
}

.accordion-button{
    background-color: var(--color-fondoSeccion);    
    color: var(--color-tituloLetra);
    font-size: 16px;
    font-weight: 500;
    text-decoration: none;
}

@media (max-width: 900px){
    #contenedorSection4{
        width: 80%;
    }

    .faq{
        margin-top: 80px;
    }
}

@media (max-width: 1500px) {
    #contenedorSection4{
        width:80%;
        margin-left: 8%;
    }

    .faq{
        margin-top: 50px;
    }
}

@media (max-width: 1200px) {
    #contenedorSection4{
        width: 80%;
    }

    .faq{
        margin-top: 50px;
    }
}

@media (max-width: 730px) {
    .parrafosAcordeon{
       display: none;
    }

    .faq__question {
        margin-top: 20px;
    }
}

@media (max-width: 1100px){
    #section4{
        height: 730px;
    }
}
