.illustration {
    width: 100%;
    position: relative;
  
    &__box {
      position: absolute;
      z-index: 999;
      top: 200px;
      left: -95px;
    }
  
    &__woman-desktop {
      position: absolute;
      top: 60px;
      left: -77px;
      clip-path: inset(0px 0px 0px 77px);
    }
  
    &__woman-mobile {
      display: none;
    }
  }