#section0{
    background-color: var(--color-navDialogs);
    background-image: url("../assets/home/section0/utiles6.png");
    background-size: cover;
    background-repeat: no-repeat;    
  }

@media (max-width: 1200px){
    #section0{
        background-image: url("../assets/home/section0/utiles0.png");
    }
}

#divTituloSection0{
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    flex-direction: column;
    width: 100%;
    height: 20rem;
}

#containerTitulo{
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    flex-direction: column;
    width: 60%;
    height: 100%;
}

#tituloSection0{
    font-size: clamp(2rem, 2vw + 2rem, 3.5rem);
    font-weight: 640;
    font-family: 'Nunito Sans','Roboto Mono', monospace;
    color: var(--color-tituloLetra);
}
#subtituloSection0{
    width: 80%;
    margin-top: clamp(2vw, 2vw + 1rem, 20px);;
    font-size: 20px;
    font-weight: 500;
    font-family: 'Nunito Sans','Roboto Mono', monospace;
    color: var(--color-tituloLetra);

}
#subtituloChicoSection0{
    width: 80%;
    margin-top: 0.8rem;
    font-size: 16px;
    font-weight: 500;
    font-family: 'Nunito Sans','Roboto Mono', monospace;
    color: var(--color-tituloLetra);
    font-style: italic;
}

#divImgSection{
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    flex-direction: column;
    width: 80vw;
    max-width: 45rem;
    height: 7rem;
    bottom: 1rem;
    transition: transform 0.3s ease;
    cursor: pointer;
    columns: 2;
} 
#divImgSection:hover{
    transition: all 0.4s ease-in-out;
    transform: translateY(-1rem);
    .circuloLink0{
        background-color: #fff;
    };
    .youtube0{
        color: #FF0000;
    }

}

#containerYoutubeLink{
    position: absolute;
    left: 0px;
}
.circuloLink0{
    width: 40px;
    height: 40px;
    /* background-color: var(--color-fondoSeccion); */
    opacity: 0.9;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 0;
    margin: 10px;
    background-color: #FF0000;
    border-radius: 20%;
    z-index: 2;
}
.circuloLink0:hover{
    background-color: #fff;
}
.redes0{
    padding: 8px;
    width: 100%;
    font-size: 18px;
    position: relative;
    font-weight: 650;
    text-decoration: none;
    margin: 0;
    width: 100%;
    color: #fff;    
    border-radius: 10px;
    z-index: 9999;
}
.youtube0:hover{
    color: #FF0000;
}

#containerImg{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    position: absolute;
}

#imgPortada{
    background-image: url('../assets/home/section0/portada.png');
    display: flex;
    justify-content: end;
    align-items: center;
    position: relative;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    border-radius: 15px;
}

#divContenedorSection0{
    width: 100%;
    height: 100%;
    padding-left: 10%;
    padding-right: 10%;
}
#divContenedorSection0 h1{
    color: var(--color-tituloLetra);
    font-size: 26px;
    font-weight: 650;
    text-decoration: none;
    align-items: center;
    margin-bottom: 3rem;
}
#contenedorSection0{
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    flex-direction: row;
    width:100%;
    height: auto;
}
#contenedorSection0 img{
    width: 50%;
    height: auto;
}

@media (max-width: 900px){
    #divTituloSection0{
        margin-top: -4rem;
    }
}

@media (max-width: 1130px){
    #containerTitulo{
        width: 90%;
    }
}