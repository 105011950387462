/* successModal.css */

.modal {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
.paper {
    width: 380px;
    background-color: var(--color-navBar);
    color: var(--color-navBarLetras);
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2);
    padding: 16px;
    position: relative;
    border-radius: 12px;
}

#loading{
    width: 100%;
    height: 100%;
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;

}

.imgLoading{
    margin-top: 10px;
}

.closeButton {
    position: absolute;
    top: 20px;
    right: 8px;
    cursor: pointer;
    width: 20%;
}
  
#simple-modal-title {
    margin: 0 0 18px 0;
    font-size: 22px;
}

#primary-modal-description {
    font-size: 26px;
}

#secondary-modal-description {
    font-size: 12px;
    width: 70%;
}
  
.modal-description {
    margin-bottom: 0;
    width: 100%;
    text-align: center;    
}

#successImage{
    width: 20%;
    height: 100%;
    margin: 10px 0 20px 0;
}

#successDescription{
    width: 100%;
    display: flex;
    justify-content: center;    
    align-items: center;
    flex-direction: column;    
}

#continueButton{
    background-color: var(--color-fondoSeccion2);
    border: none;
    color: black;
    padding: 10px 32px;
    text-align: center;
    text-decoration: none;    
    margin-bottom: 8px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 12px;
    width: 40%;
    margin-top: 20px;  

}
  