
#divContenedorFooter{
    display: flex;
    flex-direction: row ;
    justify-content: space-between;
    align-items: center;
    height: 4rem;;
    width: 100%;
    color: var(--color-navBarLetras);
}

#divTituloFooter{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: center;
    border: 0;
}

#tituloFooter{
    font-size: 16px;
    font-weight: 650;
    display: flex;
    align-items: center;
    text-align: left; 
    width: 100%;
    height: auto;
    margin-bottom: 0rem;
}

#divRedes{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: right;
    align-items: center;
    text-align: right;
    padding: 0;
}

.circuloLink{
    width: 40px;
    height: 40px;
    /* background-color: var(--color-fondoSeccion); */
    opacity: 0.9;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 0;
    margin: 10px;
    background-color: #fff;
    border-radius: 20%;
    z-index: 2;
}

.redes{
    padding: 8px;
    width: 100%;
    font-size: 18px;
    position: relative;
    font-weight: 650;
    text-decoration: none;
    margin: 0;
    width: 100%;
    color: #000000;    
    border-radius: 10px;
    z-index: 9999;
}

.circuloLink:hover{
    filter: none;
    transform: translateY(-3px) scale(1.05);
    transition: all 0.1s ease;
}

.youtube:hover{
    color: #FF0000;
}

.facebook:hover{
    color: #3B5998;
}

.instagram:hover{
    color: #E1306C;
}
.twitter:hover{
    color: #1DA1F2;
}

.linkedin:hover{
    color: #0e76a8;
}

.tiktok:hover{
    color: #000000;
}

@media (max-width:870px) {
    #divContenedorFooter{
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
        height: 9rem;
    }

    #divRedes{
        justify-content: center;
        text-align: center;
        margin-bottom: 0.5rem;
    }

    #divTituloFooter{
        justify-content: center;
        align-items: center;
    }
    #tituloFooter{
        text-align: center; 
        justify-content: center;
    }
}