.linkedin-profile {
    max-width: 600px;
    margin: 0 auto;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 16px;
    background-color: #f9f9f9;
}

.profile-header {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
}

.profile-img {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    margin-right: 16px;
}

.profile-info {
    flex: 1;
}

.profile-name {
    font-size: 1.5rem;
    font-weight: bold;
    margin: 0;
}

.profile-title {
    font-size: 1.2rem;
    color: #555;
}

.profile-location {
    font-size: 1rem;
    color: #777;
}

.profile-summary {
    margin-bottom: 16px;
}

.profile-skills {
    border-top: 1px solid #ddd;
    padding-top: 16px;
}

.profile-skills h3 {
    margin-top: 0;
}

.profile-skills ul {
    list-style-type: none;
    padding: 0;
}

.profile-skills li {
    background: #e1e1e1;
    margin: 5px 0;
    padding: 5px;
    border-radius: 4px;
}
