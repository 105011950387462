#section3{
  background-color: var(--navBarLetras);
}
#divMenuSection3{
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    flex-direction: column;
    width: 60rem;
    height: 24rem;
}
#divContenedorSection3{
    width: 100%;
    height: 100%;
}
#divContenedorSection3 h1{
    color: var(--color-tituloLetra);
    font-size: 26px;
    font-weight: 650;
    text-decoration: none;
    align-items: center;
    margin-bottom: 3rem;
}
#contenedorSection3{
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    flex-direction: row;
    width:100%;
    height: auto;
}
#contenedorSection3 img{
    width: 50%;
    height: auto;
}
#divTextSection3{
    display: flex; /* Convertirlo en un contenedor flexible */
    flex-direction: column; /* Para alinear el texto de arriba hacia abajo */
    align-items: flex-end;
    padding: 10px;
    text-align: right; 
    margin-top: 3rem;
    justify-content: flex-end;
}
#divTextSection3 h2{
    color: var(--color-tituloLetra);
    font-size: 20px;
    position: relative;
    font-weight: 650;
    text-decoration: none;
    align-items: center;
    margin-bottom: 10px;
    text-align: right; 
}
#divTextSection3 h3{
    color: var(--color-subtituloLetra);
    font-size: 16px;
    font-weight: 600;
    text-decoration: none;
    align-items: center;
    margin-bottom: 10px;
    letter-spacing: 0.2px;
}


@media (max-width: 700px) {
    #divTextSection3{
        width: 80%;
        text-align: center !important; 
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    #divMenuSection3{
        margin-top: -70px;
        text-align: center;
    }
    #divContenedorSection3 h1{
        margin-top:-5rem;
    }
    #contenedorSection3{
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        flex-direction: column-reverse;
        width:100%;
        height: auto;
        text-align: center;
    }
    #contenedorSection3 img{
        width: 20rem;
        height: auto;
    }   
    p.tituloh2{
        text-align: center;
    }
} 

