@import url('https://fonts.googleapis.com/css2?family=Righteous&family=Work+Sans:wght@100;300;400;600;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300&family=Righteous&family=Roboto+Mono:wght@100;400&family=Work+Sans:ital,wght@0,800;1,100;1,200;1,300;1,400;1,500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@300;400;600;800&display=swap');
@import url('./section1.css');

/* NAV Logo*/
#logoHeader{
  position: absolute;
  height: 65px;
  width: 280px;
  left: 3%;
  background-color: var(--color-navBar);
  border-radius: 0 0 10px 10px;
  display: flex;
  align-items: center;
  padding: 10px 25px 0px 15px;
  justify-content: space-between;
  top:-5px;
}
#logoHeader.scrolled{
  position: absolute;
  transition: .2s;
  background-color: var(--color-navBar);
  height: 50px;
  left:5%;
  top:1px;
}
.logo img{
  height: 56px;   
  position: relative;
  transition: .2s;
}
.logo.scrolled img{
  transition: .2s;
  height: 58px;   
  border-radius: 0;
  transition: all 0.2s ease;
}
.logo img:after{
  transform-origin: center;
  will-change: transform;
  transform-style: preserve-3d;
  backface-visibility: hidden;
  transition: transform 1s ;
  transform: scale(1.1) rotateY(360deg);
  pointer-events: none;
}
#titulo{
  color: var(--color-navBarLetras);
  font-size: 14px;
  letter-spacing: 1px;
  font-weight: 600;
  font-family: 'Nunito Sans','Roboto Mono', monospace;
  cursor: pointer;
  position: relative;
  margin-top: 5px;
}

/* NAV "EXPLORAR" */
#explorarHeader{
  color: var(--color-navDialogs);
  align-content: center;
  text-align: center;  
  display: flex;
  justify-content: center; 
  align-items: center; 
  position: absolute;
  left: 40rem;
  top: 21px;
}
#explorar{
  color: var(--color-navBarLetras);
  font-family: "Nunito Sans";
  font-size: 14px;
  letter-spacing: 1px;
  font-weight: 400;
  transition: .3s ease-in-out;
  text-decoration: none;
  align-items: center;
  cursor: pointer;
}
#explorarHeader.scrolled{
  display: none;
}
#explorarHeader :hover{
  color: var(--color-letraLogo);
}
/* NAV DROPDOWN MENU */
.ant-dropdown-link{
  color: var(--color-navBarLetras);
  font-size: 16px;
  letter-spacing: 1px;
  font-weight: 600;
  font-family: 'Nunito Sans','Roboto Mono', monospace;
  cursor: pointer;
  position: relative;
  background-color: var(--color-navBar);
  border: none;
  top:3px;
}

.ant-dropdown{
  border: none;
  border-radius: 10px;
  font-size: 16px;  
  color: var(--color-navBarLetras); 
  z-index: 9999;
}
.ant-dropdown .scrolled{
  font-size: 16px;
}
.ant-dropdown-menu-item :hover{
  color: var(--color-letraLogo);
}

.ant-dropdown-menu-item a{
  text-decoration: none;
  color: var(--color-navBar);
}

/* NAV Links */
#navLinksDiv{
  opacity: 1;
  width: 490px;
  height:42px;
  background-color: var(--color-navBar);
  position:absolute;
  top: 14px;
  right: 5%;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;    
  align-content: center;
  text-align: center;  
  display: flex;
  justify-content: center; /* Alinea horizontalmente */
  align-items: center; /* Alinea verticalmente */
}
#navLinksDiv.scrolled{
  transition: .2s;
  background-color: var(--color-navBar);
}

.separatorMenu{
  height: 1px; 
  width: 100%; 
  background-color: azure; 
  position: relative;
}

/* NAV menu */
#navMenu {
  display: flex;
  justify-content: center;
  align-items: center;
  position : absolute;
  width:100%;
  height: 73vh;
  top: 65px;
  right: 0%;
  background: var(--color-navBar);
  backdrop-filter: blur(15px);
  border-radius: 20px;
  transition: height 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  backdrop-filter: blur(15px);
}

@media (max-width: 900px){
  #navMenu{
    height: 85vh;
  }
}
#navLinksMenuLateral{
  display: flex;
  margin-top: 2rem;
  justify-content: center;
  transition: all 0.3s ease;
}
#navLinksMenuLateral ul{
  display: flex;
  flex-direction: column;
  columns: 1;
  align-items: center;
}
.navlinksmenu{
  color: var(--color-navBar);
  padding: 1.5rem;
  display: flex;
  align-items: center;  
  transform: scale(1.4);
  font-weight: 500;
  font-family: 'Nunito Sans','Roboto Mono', monospace;
  text-decoration: none;
  text-align: center;
  justify-content: center;
  transition: .5s;
}

.espaciado{
  left:10%
}

#menuLateral {
  opacity: 1;
  position : absolute;
  color: var(--color-navBarLetras);
  cursor: pointer;
  top: 20%;
  right: 5%;
  transition: height 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
#menuLateral i {
  font-size: 34px; 
}


/* NAV "PEDI TU CLASE"! */
.contacto {
  position: relative;
  margin: auto;
  padding: 19px 25px;
  transition: all 0.2s ease;
}
.contacto:before {
  content: "";
  position: absolute;
  top: 11px;
  left: 0;
  display: block;
  border-radius: 28px;
  background: var(--color-letraLogo);
  width: 36px;
  height: 36px;
  transition: all 0.3s ease;
}
.contacto span {
  position: relative;
  top: -1px;
  font-size: 15px;
  line-height: 18px;
  font-weight: 650;
  text-transform: uppercase;
  vertical-align: middle;
  right:5px;
  z-index: 99999;
}

.contacto:hover:before {
  width: 210px;
  background: #29d7aeba;
  opacity: 0.3;
}
.contacto:hover svg {
  transform: translateX(0);
}
.contacto:active {
  transform: scale(1.4);
}

@media (max-width: 900px){
  #sectionHeader{
    transition: all 0.3s ease-in-out;
    background-color: var(--color-fondoSeccion);
  }
}

/* NAV Bar */
.contenedor-header{
  background : #18171c;
  position: fixed;
  width: 100%;
  height: 61px;
  left: 0;
  z-index: 9999;
  transition: all 0.4s; 
  top: -5px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.contenedor-header header{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 17px 15px 0 15px;
  transition: all 0.3s ease;
}
.contenedor-header header .logo a{
  color: #1CB698;  
}
.contenedor-header header ul{
  display: flex;
  list-style: none;
}
/* .contenedor-header header nav ul li a{*/
.navlinksdiv, .contenedor-header header nav ul li a{
  color: var(--color-navBarLetras);
  font-size: 14px;
  letter-spacing: 1px;
  font-weight: 400;
  font-family: 'Roboto Mono', monospace;
  margin: 10px 30px 0px 0px;
  left: 12%;
  position: relative;
  transition: .5s;
  text-decoration: none;
  align-items: center;
  cursor: pointer;
}
.contenedor-header header nav ul li a:not(#botonCotiza):hover{
  color: #1CB698;
}
.contenedor-header.scrolled{
  box-shadow: 0 0 7px rgba(0,0,0,0.4);
  transform: scale(0.9);
  transition: ease-in-out 0.18s;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  background-color: var(--color-navBar);
  top:-6px;
  
  z-index: 9999;
}

