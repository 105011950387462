#section5{
    display: flex;
    flex-direction: left;
    justify-content: left;
    align-items: center;
    background-color: var(--color-fondoSeccion2);    
    background-size: cover;
    background-repeat: no-repeat;   
    font-family: 'Nunito Sans', 'Work Sans';

}
#divMenuSection5{
    display: flex;
    justify-content: left;
    flex-direction: row;
    align-items: left;
    background-color: #f5f5f5;
    margin-left: 5%;
    border-radius: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    height:80%;
    width: 70%;
    font-family: 'Nunito Sans', 'Work Sans';
}

/* @keyframes fadeInOut {
    0% {
      opacity: 0;
    }
    80% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  } */

#contact-info{
    min-width: 450px;
    margin: 0 5% 0 5%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 60%;
    border-radius: 30px;
    /* animation: fadeInOut 7s infinite; */
    transition: opacity 1s ease;
    /* background-image: url("../assets/section5/a.jpg") ; */
    /* background-size: cover;
    background-repeat: no-repeat; */
}

#contact-info-image{
    width: 100%;
    height: 100%;
    border-radius: 30px;
    box-shadow: 10px 10px 9px rgba(0, 0, 0, 0.1);;
}

/* #contact-info {
    transition: opacity 1s ease-in-out; 
    opacity: 0; 
  } 
#contact-info.active {
    opacity: 1; 
}
*/
@media (max-width: 1400px){
    #contact-info{
        min-width: 350px;
    }    
}

@media (max-width: 1000px){
    #contact-info{
        display: none;
    }    
    #divMenuSection5{
        width: 90%;
        margin-left: 0;
    }
    #section5{
        justify-content: center;
        align-items: center;
    }
}
#contact-container{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
}

#contact-title{
    display: flex;
    text-align: center;
    align-items: left;
    justify-content: left;
    font-size: 2rem;
    font-weight: 600;
    color: var(--color-tituloLetra);
    margin: 0;
    padding: 25px;
    width: 100%;
    height: 20%;
}

#contact-submit{
    background-color: var(--color-fondoSeccion2); /* Color de fondo */
    border: none;
    color: black;
    padding: 10px 32px;
    text-align: center;
    text-decoration: none;    
    margin-bottom: -8px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 12px; /* Bordes redondeados */
    transition: background-color 0.3s, transform 0.3s;
}

#contact-submit:hover {
    background-color: #73ecfc; /* Color de fondo al pasar el ratón */
    transform: scale(1.05); /* Ligero aumento de tamaño al pasar el ratón */
}
#contact-submit:active {
    background-color: #00e1ff;  /* Color aún más oscuro al hacer clic */
    transform: scale(0.95); /* Ligera disminución de tamaño al hacer clic */
}

#contact-form{
    display: flex;
    width: 100%;
    height: 350px;
    flex-flow: row wrap;
}

@media (max-width: 600px){
    #contact-form{
        height: 400px;;
    }
    #section5{
        height: 700px;
    }
}

@media (max-width: 540px){
    #contact-form{
        height: 480px;;
    }
    #section5{
        height: 850px;
    }
}

.MuiTextField-root{
    width: 100%;
}

.MuiSelect-root{
    width: 100%;
}

.contact-div {
    margin: 20px;
    height: auto;
    text-align: center;
    flex-grow: 1;
    width: 25%;
}

.error{
    color: red;
    font-size: 0.85rem;
    margin: 0;
    padding: 0;
}

.MuiList-root{
    max-height: 300px;
}
