#section2{    
    background-color: var(--color-fondoSeccion2);
  }
#divMenuSection2{
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    flex-direction: column;
    width: 60rem;
    height: 24rem;
}
#divContenedorSection2{
    width: 100%;
    height: 100%;
}
#divContenedorSection2 h1{
    color: var(--color-tituloLetra);
    font-size: 26px;
    font-weight: 650;
    text-decoration: none;
    align-items: center;
    margin-bottom: 3rem;
}
#contenedorSection2{
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    flex-direction: row;
    width:100%;
    height: auto;
}
#contenedorSection2 img{
    width: 50%;
    height: auto;
}
#divTextSection2{
    display: flex; /* Convertirlo en un contenedor flexible */
    flex-direction: column; /* Para alinear el texto de arriba hacia abajo */
    align-items: center;
    padding: 10px;
    text-align: left; 
    margin-top: 3rem;
}
#divTextSection2 h2{
    color: var(--color-tituloLetra);
    font-size: 20px;
    font-weight: 650;
    text-decoration: none;
    align-items: center;
    margin-bottom: 10px;
}
#divTextSection2 h3{
    color: var(--color-subtituloLetra);
    font-size: 16px;
    font-weight: 600;
    text-decoration: none;
    align-items: center;
    margin-bottom: 10px;
    letter-spacing: 0.2px;
}

@media (max-width: 1000px) {    
    #divTextSection2{
        width: 80%;
    }
}

@media (max-width: 700px) {
    #divContenedorSection2 h1{
        margin-top:-5rem;
    }
    #contenedorSection2{
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        flex-direction: column;
        width:100%;
        height: auto;
    }
    #contenedorSection2 img{
        width: 20rem;
        height: auto;
    }
    #divTextSection2{
        width:80%;
        text-align: center;
    }
}   

@media (max-width:500px) {
    #section2{  
        height: 680px;
    }
    #divMenuSection2{
        margin-top: -70px;
    }
}

