:root{
  --color-navBar: #18171c;
  --color-navBarLetras: #f2f2f2;
  --color-navDialogs: #fff;
  --color-tituloLetra:#18171c;
  --color-subtituloLetra:#a55f4e;
  --color-fondoSeccion:#fff;
  --color-fondoSeccion2: #d4f9ff;
  --color-comentarioCard: #00000093;
  --color-letraLogo: #67f8d6cb;
}
/* Gral */
*{
  box-sizing: border-box;
  font-family: 'Nunito Sans','Work Sans';
  margin: 0;
  padding: 0;
}
html{
  scroll-behavior: smooth;  /*Me permite deslizar cuando hago click en el menú*/
  background: #fff;
  z-index: 1;
  transition: all 0.3s ease;
  width: 100%;
  height: auto;
  margin: 0;
  padding: 0;
  font-size: 16px; 
}
body {
  width: 100%;
  margin: 0;
  padding: 0;
  font-family: 'Nunito Sans','Roboto Mono', monospace;
  overflow-x: hidden;
  padding-right: 0 !important;
}
a{
  text-decoration: none;
}
header {
  font-family: 'Nunito Sans','Roboto Mono', monospace;
  -webkit-font-smoothing: antialiased;
  text-decoration: none;
  transition: all 0.3s ease;
  height: 0px;
  padding: 0px;
  margin: 0px;
  width: 100%;
}
footer{
  position: relative;
  width: 100%;
  text-align: center;
  align-items: center;
  display: flex;  
  background-color: var(--color-navBar);
}
.contenedorIndex{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.section{
  height: 40rem;
  position: relative;
  width: 100%;
  text-align: center;
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 0;
  padding: 0;
}

p{
  margin: 0;
}

.texto{
  margin-top: 5px;;
  font-size: 0.9rem;
  font-weight: 400;
  text-decoration: none;
  align-items: center;
  letter-spacing: 0.3px;
  margin-bottom: 10px;
  color: black;
  margin-bottom: 20px;
}
.comentario{
  font-size: 0.9rem;
  font-weight: 400;
  text-decoration: none;
  align-items: center;
  color: var(--color-comentarioCard);
  text-anchor: middle;
}
.tituloh1{
  color: var(--color-tituloLetra);
  font-size: 1.5rem;
  font-weight: 650;
  text-decoration: none;
}
.tituloh2{
  color: var(--color-tituloLetra);
  font-size: 1.4rem;
  font-weight: 650;
  text-decoration: none;
  align-items: center;
  margin-bottom: 2px;
}
.tituloh3{
  color: var(--color-subtituloLetra);
  font-size: 1.05rem;
  font-weight: 600;
  text-decoration: none;
  align-items: center;
  margin-bottom: 15px;
  letter-spacing: 0.2px;
}