#section9{    
    background-color: var(--color-fondoSeccion);
  }

  #divMenuSection9{
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    flex-direction: column;
    width: 100%;
    height: 100%;
}
#divContenedorSection9{
    width: 100%;
    height: 100%;
}
#contenedorSection9{
    display: flex;
    justify-content: center;
    align-items: center;
    width:100%;
    height: auto;
    background-color: rgba(240, 255, 255, 0.459);
}
#divTextSection9{
    display: flex; /* Convertirlo en un contenedor flexible */
    flex-direction: column; /* Para alinear el texto de arriba hacia abajo */
    align-items: center;
    padding: 10px;
    text-align: center; 
    width: 40rem;
    height: 10rem;
    justify-content: center;
}
#tituloVideos {
    color: var(--color-tituloLetra);
    font-size:50px;
    font-weight: 650;
    text-decoration: none;
    align-items: center;
}