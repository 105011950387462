.faq {
    width: 800px;
    padding-top: 65px;
    padding-bottom: 80px;
  
    &__title {
      margin: 0;
      padding-bottom: 25px;
    }
  
    &__question {
      width: 100%;
      border-bottom: 1px solid $light-grayish-blue;
    }
  
    &__question-button {
      width: 100%;
      display: flex;
      justify-content: space-between;
      border: none;
      background-color: transparent;
      padding: 0px;
      cursor: pointer;
  
      padding-top: 18px;
      padding-bottom: 18px;
  
      &::after {
        content: url(../images/icon-arrow-down.svg);
        margin-right: 17px;
        transform: rotate(90deg);
      }
  
      &:hover {
        color: $soft-red;
      }
    }
  
    &__desc {      
      font-size: 14px;
      line-height: 18px;
      font-weight: 400;
      text-align: center;
      width: 95%;
      margin-top: 0;
      margin-bottom: 0;
      height: 0;
      overflow: hidden;
      transition: all 200ms ease-out;
      max-width: 100%;
    }
  }