#contenedorVideos {
    width: 60%;
}

.videos{
    width: 350px;
    height: 200px;
}

@media (max-width: 1800px) {
    .videos {
        scale: 0.95;
    }    
}

@media (max-width: 1700px) {
    .videos {
        scale: 0.9;
    }    
}

@media (max-width: 1600px) {
    .videos {
        scale: 0.85;
    }    
}

@media (max-width: 1520px) {
    .videos {
        scale: 1;
    }  

    #contenedorVideos {
        width: 50%;
    }      
}

@media (max-width: 1500px) {
    .videos {
        scale: 0.95;
    }  
}

@media (max-width: 1500px) {
    .videos {
        scale: 0.9;
    }  
    #contenedorVideos {
        width: 50%;
    } 
}

@media (max-width: 1350px) {
    .videos {
        scale: 0.9;
    }  
    #contenedorVideos {
        width: 55%;
    } 
}

@media (max-width: 1290px) {
    .videos {
        scale: 0.85;
    }     
}

@media (max-width: 1100px) {
    .videos {
        scale: 1;
    }     

    #contenedorVideos {
        width: 350px;
    } 
}