
#section1{
  background-color: var(--color-fondoSeccion);
  justify-content: center;
  align-items: center;
  text-align: center;
}

#descriptionSection1{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 0 150px 20px 150px ;
    width: 20rem;
    position: absolute;
    left: 0rem;    
    color: var(--color-comentariocardServicio);
}

@media (max-width: 1550px){
  #descriptionSection1{
    display: none;    
  }
}

#cardServicioMenu{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px;
    width: 60rem;
}
.carousel{
  display: flex;  
  flex-direction: row;
  position: absolute;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.cardServicio{
    width: 450px; 
    height: 500px;
    border-radius: 20px;
    -webkit-font-smoothing: antialiased;
    padding: 10px;
    transition: transform 0.3s ease-in-out, box-shadow 0.1s ease;
    will-change: transform;
    transform-style: preserve-3d; /* Forzar la aceleración por hardware */
    cursor: pointer;
    background-color: azure;
    text-decoration: none;
    box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.1);
    border: 2px solid azure; 
    opacity: 0.95;
}

.cardServicio:hover{
  border: 2px solid #C9F5FA; 
  opacity: 1;
  box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.3);
}

.cardServicio.active {
  display: none;
}

.arrow{  
  font-size: 50px;
  font-weight: 700;
  color: #3f3f3f;
  z-index: 1;
  cursor: pointer;
  border:none;
  background: none;
  transform: translate(0, -50%);
}

.divArrow{
  height: auto;
  position: relative;
  margin: 50px 50px 0px 50px;
}

.contentcardServicio{
  width: 100%;
  height: 100%;
  border-radius: 10px;
  transition: transform 0.5s ease; 
}

.cardServicio img{
    width: 100%;
    height: 250px;
    border-radius: 10px;
    transition: all 0.2s ease;
    margin-bottom: 20px;
    background-size: cover;
}
.separator {
    height: 350px; 
    width: 1px; 
    background-color: #2b2b2b3a; 
    position: relative;
}

@media (max-width: 700px) {
   .cardsContainer{
     scale: 0.9;
   }

   .divArrow{
      margin: 50px 0px 0px 0px;
   }
}

@media (max-width: 570px) {
  .divArrow{
    margin: 50px -10px 0px -10px;
 }
}

@media (max-width: 550px) {
  .cardsContainer{
    scale: 0.9;
  }

  .divArrow{
    position: absolute;
    top: 530px;
  }

  .leftArrow{
    position: absolute;
    left: -120px;
    background-color: #EBF9F9;
    border-radius: 10px;
    box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.2);
  }

  .rightArrow {
    position: absolute;
    right: -120px;
    background-color: #EBF9F9;
    border-radius: 10px;
    box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.2);
  }
}

@media (max-width: 900px) {
  .cardsContainer{
    scale: 0.85;
  }
}

