
#section6{
    background-color: var(--color-fondoSeccion2);
    justify-content: center;
    align-items: center;
    text-align: center;
    display: flex;
    align-items: center;
}
  
#descriptionSection6{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 0 120px 0px 150px;
    width: 20rem;
    position: absolute;  
    top: 45%;  
    right: 0rem;    
    color: var(--color-comentariocardServicio);
}

@media (max-width: 1550px){
    #descriptionSection6{
      display: none;    
    }
  }

  #perfilLinkedin{
    margin-top: 1rem;
    border-radius: 10px;
    width: 550px;
    border: 1px solid #C9F5FA;
  }

  #perfilLinkedin:hover{
    border: 1px solid #C9F5FA; 
    opacity: 1;
    box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.3);
    cursor: pointer;
  }

  @media (max-width: 1550px){
    #perfilLinkedin{
      width: 95%;
    }
  }
  

