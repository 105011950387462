@media only screen and (max-width: 800px) {
    .illustration__woman-desktop {
      max-width: 120%;
    }
  }
  
  @media only screen and (max-width: 700px) {
    .container {
      /* added padding here to mobile container,
      so illustration__woman-desctop would not be cropped at the top */
      padding-top: 105px;
    }
  
    .component {
      max-width: 327px;
      background-position: top;
      flex-direction: column;
    }
  
    .illustration {
      &__box {
        display: none;
      }
  
      &__woman-desktop {
        display: none;
      }
  
      &__woman-mobile {
        display: block;
        margin: 0 auto;
        position: absolute;
        top: -105px;
        left: 12px;
      }
    }
  }